import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { retrieveSessionToken, storeSessionToken } from "./session-token";
import { catchError } from "rxjs/operators";
import { inject } from "@angular/core";
import { ActionSnackService, UpgradeService } from "common";
import { throwError } from "rxjs";

let loginSnackShown = false;

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
    const actionSnackService = inject(ActionSnackService);
    const upgradeService = inject(UpgradeService);
    const authToken = retrieveSessionToken();

    const request = (authToken != null)
        ? req.clone({headers: req.headers.set("Authorization", "Bearer " + authToken)})
        : req;

    return next(request).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401 && !loginSnackShown) {
                loginSnackShown = true;

                actionSnackService.showSnack({
                    message: "Istuntosi on vanhentunut",
                    actionText: "Päivitä istunto",
                    action() {
                        storeSessionToken(null);
                        upgradeService.upgradeNow();
                    }
                });
            }

            return throwError(() => error);
        }));
};
