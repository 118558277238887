import { LoginResult, OAuthHandler } from "common";
import { LoginEndpoint, OAuthLoginSettings } from "apina-frontend";
import { firstValueFrom } from "rxjs";
import { AuthenticationResult } from "@azure/msal-browser";
import { Injectable } from "@angular/core";
import { storeSessionToken } from "./session-token";

@Injectable({providedIn: "root"})
export class FrontendOAuthHandler implements OAuthHandler {

    constructor(private readonly loginEndpoint: LoginEndpoint) {
    }

    getLoginSettings(): Promise<OAuthLoginSettings> {
        return firstValueFrom(this.loginEndpoint.getLoginSettings());
    }

    async loginInternally(authenticationResult: AuthenticationResult): Promise<LoginResult> {
        const result = await firstValueFrom(this.loginEndpoint.loginWithAzureToken(authenticationResult.accessToken));
        storeSessionToken(result.jwtToken);
        return {type: "ok"};
    }

    async isLoggedIn(): Promise<boolean> {
        try {
            const status = await firstValueFrom(this.loginEndpoint.getLoginStatus());
            return status.loggedIn;
        } catch (e) {
            return false;
        }
    }
}
