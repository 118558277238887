import { ChangeDetectionStrategy, Component, signal } from "@angular/core";
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from "@angular/router";
import { getCookieValue } from "common";
import { TranslateService } from "@ngx-translate/core";
import localeSwedish from "@angular/common/locales/sv";
import { JsonPipe, registerLocaleData } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { frontendTranslations } from "./l10n/frontend.translations";
import { environment } from "../environments/environment";
import { registerTimesheetTranslations } from "operator-timesheet";
import { MainMenuComponent } from "./menu/main-menu.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        JsonPipe,
        RouterOutlet,
        MainMenuComponent,
        MatProgressSpinnerModule,
    ]
})
export class AppComponent {

    readonly menuSection = signal<string | null>(null);
    readonly loading = signal(false);
    readonly error = signal<NavigationError | null>(null);
    readonly showErrorDetails = !environment.production;

    constructor(
        router: Router,
        private readonly title: Title,
        translateService: TranslateService,
    ) {
        router.events.pipe(takeUntilDestroyed()).subscribe(e => {
            if (e instanceof NavigationStart) {
                this.error.set(null);
                this.loading.set(true);
            } else if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
                this.loading.set(false);
            } else if (e instanceof NavigationError) {
                console.error("navigation error", e);
                this.error.set(e);
                this.loading.set(false);
            }
        });

        initTranslations(translateService, getCookieValue("language"));
    }

    routeActivated(route: ActivatedRoute | null): void {
        this.menuSection.set(route?.snapshot?.data["menuSection"]);
    }

    routeDeactivated(): void {
        // Some pages customize the page title, so we need to reset the title when we move out of the page.
        this.title.setTitle("Pilotweb");
    }
}

function initTranslations(translateService: TranslateService, useLanguage: string | undefined): void {
    registerTimesheetTranslations(translateService);

    translateService.setTranslation("fi", frontendTranslations.fi, true);
    translateService.setTranslation("sv", frontendTranslations.sv, true);

    translateService.defaultLang = "fi";

    if (useLanguage != null && translateService.getLangs().includes(useLanguage))
        translateService.use(useLanguage);
    else
        translateService.use("fi");

    registerLocaleData(localeSwedish);
}
