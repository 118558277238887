import { ChangeDetectionStrategy, Component } from '@angular/core';
import { storeSessionToken } from "../session-token";
import { RouterLink } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";

@Component({
    selector: 'app-logout',
    standalone: true,
    imports: [
        MatButtonModule,
        MatCardModule,
        RouterLink,
    ],
    templateUrl: './logout.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "class": "!block",
    }
})
export class LogoutComponent {

    constructor() {
        storeSessionToken(null);
    }
}
